// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-down-js": () => import("../src/pages/down.js" /* webpackChunkName: "component---src-pages-down-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-left-js": () => import("../src/pages/left.js" /* webpackChunkName: "component---src-pages-left-js" */),
  "component---src-pages-right-js": () => import("../src/pages/right.js" /* webpackChunkName: "component---src-pages-right-js" */),
  "component---src-pages-up-js": () => import("../src/pages/up.js" /* webpackChunkName: "component---src-pages-up-js" */)
}

